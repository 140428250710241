// Imports
const { render } = wp.element

const generateBreadCrumb = (pathname) => {
    let paths = pathname.split("/")
        
    // remove the last element if there was a / at the end of the pathname
    paths = paths[paths.length-1] === "" ? paths.slice(0, paths.length-1): paths
    
    // remove the first element if the second one is an empty string which means that we are in the root of the website
    paths = paths[1] === "" ? paths.slice(1) : paths  

    const breadcrumb = paths.map((path, index) => {

        // Replace "-" with spaces so our breadcrumbs look more friendly
        path = path.replace(/-/g, ' ')

        // Add the > symbol only between two links
        let arrow = index !== paths.length-1 ? " / " : " "

        // Remove the word "Product Category" from breadcrumbs as this page is just to make URL's look nice.
        if( path === 'product category' ) {
            return (
                null
            )
        }



        // If we enter a product, the URL structure changes to /product/ which isn't very informative. Let's try get them back to a category.
        if( path === 'product' ) {
            // We can fetch all our data from the product page, but first, let's check this information is available
            if( document.querySelector('.posted_in a') ) {
                const childCategoryParent = document.querySelector('.posted_in a')
                let childCategoryName = childCategoryParent.innerHTML 
                const childCategoryUrl = childCategoryParent.href
                childCategoryName = childCategoryName.replace(/&amp;/g, "&")

                return (
                    <li>
                        <a href={childCategoryUrl}>
                            {childCategoryName}
                        </a>
                        {arrow}
                    </li>
                )
            } else {
                // If we can't find out category data, let's not display a breadcrumb and take them straight to the store
                return (
                    <li>
                        <a href="/shop">
                            All Products
                        </a>
                        {arrow}
                    </li>
                )
            }

        }
        
        // The first element should receive the <IndexLink> React element
        if (index === 0) {
            return (<li><a href="/" className="active">Home</a>{arrow}</li>)
        }
        
        // Build the path for the current URL
        const url = paths.slice(0, index+1).join('/')
        
        // HTML structure for every link except the first
        return (
            <li>
                <a href={url}>
                    {path}
                </a>
                {arrow}
            </li>
        )
    })
        
    // Return a list of links
    return (
        <ul className="inline-list">
            {breadcrumb}
        </ul>
    )
}

const Breadcrumbs = () => {
    // When rendering the component, calculate the HTML structure of the breadcrumb
    const breadcrumb = generateBreadCrumb(window.location.pathname)
    
    // If we're on the search page
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const search = urlParams.get('s')

    if( search ) {
        return(
            <div className="totw-breadcrumbs search-results-breadcrumbs">
                <ul className="inline-list">
                    <li>
                        Search results for: <b>{search}</b>
                    </li>
                </ul>
            </div>
        )
    } else {
        return (
            <div className="totw-breadcrumbs">
                {breadcrumb}
            </div>
        )
    }
}

// Only attempt to display our elemtn if the correct HTML is in the page template.
if( document.getElementById('breadcrumbs') ) {
    render(<Breadcrumbs />, document.getElementById('breadcrumbs')) 
}
